<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    message?: string | string[],
  }>()

  const text = computed(() => {
    // null / empty string / empty array
    if (!props.message || props.message.length === 0) {
      return null
    }

    // array
    if (Array.isArray(props.message)) {
      return props.message[0]
    }

    // string
    return props.message
  })
</script>

<template>
  <div
    v-if="text"
    class="error-message">
    {{ text }}
  </div>
</template>

<style lang="postcss" scoped>
/* the class is used in api.ts scrolltoview on error 422 */
.error-message {
  @apply py-1 text-xs text-red rounded;
  @apply scroll-mt-64; /* offset navbar top */
}
</style>
