<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  defineProps<{
    placeholder?: string,
  }>()

  const route = useRoute()
  const router = useRouter()

  const emit = defineEmits(['submit'])
  const search = ref(String(route.query.search ?? ''))

  const submitForm = () => {
    router.push({
      query: {
        // maintain other query params, but reset pagination
        ...route.query,
        page: undefined,
        // append or remove search to query params
        search: search.value ? search.value : undefined,
      },
    })
    emit('submit', search.value)
  }

  watch(() => route.query.search, newVal => {
    if (newVal !== search.value) {
      search.value = String(newVal ?? '')
      emit('submit', search.value)
    }
  })
</script>

<template>
  <app-input-search
    v-model="search"
    :placeholder="placeholder"
    @submit="submitForm"></app-input-search>
</template>
