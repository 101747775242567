<script setup lang="ts">
  import { useApi } from '@/functions/api'

  const api = useApi()

  api.GET('/sanctum/csrf-cookie')
</script>

<template>
  <router-view></router-view>
  <app-toast></app-toast>
</template>
