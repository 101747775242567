<script setup lang="ts">
  import { computed } from 'vue'

  // source https://flowbite.com/docs/forms/toggle/
  const props = defineProps<{
    modelValue: boolean,
    disabled?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', data: boolean): void,
  }>()

  const value = computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    },
  })
</script>

<template>
  <label class="relative inline-flex cursor-pointer items-center">
    <input
      v-model="value"
      type="checkbox"
      class="peer sr-only">
    <div class="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4 after:border after:border-gray-300 after:rounded-full after:bg-white peer-checked:bg-primary-600 peer-focus:outline-none after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
  </label>
</template>
